import axios from "axios";

export const getBotMessage = async (data) => {
  try {
    const response = await axios.post(
      "https://dev.queryloop-ai.com/api/eval_bot/chat/neo",
      data
    );

    // Check if response is an object and try to extract text if possible
    let botMessage;
    if (typeof response.data === "string") {
      botMessage = response.data;
    } else if (response.data?.choices?.[0]?.message?.content) {
      botMessage = response.data.choices[0].message.content;
    } else {
      botMessage = response.data; // Fallback for unknown structures
    }
    console.log("first", response);
    return botMessage;
  } catch (error) {
    console.error("Error fetching bot response:", error);
    return "Sorry, I couldn't retrieve a response. Please try again later."; // Fallback message
  }
};
