import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import ProfileImageCard from "../../components/ProfileImageCard";
import ChatInterface from "../../components/ChatInterface";
import colors from "../../utils/colors";

function Home() {
  const [isChatOpen, setIsChatOpen] = useState(false);

  const handleTalkToMe = () => {
    setIsChatOpen(true);
  };

  const handleCloseChat = () => {
    setIsChatOpen(false);
  };

  const sharedSize = {
    height: { xs: "50vh", sm: "66vh" },
    width: "auto",
    maxWidth: "600px",
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        backgroundColor: colors.background,
        color: colors.textPrimary,
        // pt: 4,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          minHeight: "calc(100vh - 60px)",
          maxWidth: "1200px",
          mx: "auto",
          px: 2,
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        {/* Left Section - Description and Talk to Me Button */}
        <Box sx={{ maxWidth: 400, textAlign: { xs: "center", md: "left" } }}>
          <Typography variant="h2" sx={{ fontWeight: "bold" }}>
            Hi, I'm Neo.
          </Typography>
          <Typography variant="h6" sx={{ mt: 2 }}>
            Your AI consultant for creating Gen AI applications.
          </Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            I'm here to help you find the right AI solution, whether that's a
            simple GPT wrapper or an advanced system that knows your data and
            takes actions the way you would.
          </Typography>

          <Typography variant="body1" sx={{ mt: 2 }}>
            Let's start chatting and I will share a plan for building your app
            within 24 hours. Don't worry, in case I get stuck, I have human AI
            experts available to assist me.
          </Typography>

          {!isChatOpen && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleTalkToMe}
              sx={{
                mt: 3,
                textTransform: "none",
                borderRadius: "20px",
                px: 3,
                color: colors.textSecondary,
                backgroundColor: colors.buttonBorder,
              }}
            >
              Talk to Me
            </Button>
          )}
        </Box>

        {/* Right Section - Profile Image or Chat Interface */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
            mt: { xs: 4, md: 0 },
          }}
        >
          <AnimatePresence>
            {!isChatOpen && (
              <motion.div
                key="profileImage"
                initial={sharedSize}
                animate={sharedSize}
                exit={{ height: 60, width: 60 }}
                transition={{ duration: 0.5 }}
              >
                <ProfileImageCard sx={sharedSize} />
              </motion.div>
            )}

            {isChatOpen && (
              <motion.div
                key="chatInterface"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                style={{ ...sharedSize }}
              >
                <ChatInterface onClose={handleCloseChat} />
              </motion.div>
            )}
          </AnimatePresence>
        </Box>
      </Box>
    </Box>
  );
}

export default Home;
