// src/components/ProfileImageCard.jsx
import React from "react";
import { Box } from "@mui/material";
import profileImage from "../assets/bot-image.jpg";

function ProfileImageCard({ sx }) {
  return (
    <Box
      component="img"
      src={profileImage}
      alt="Profile"
      sx={{
        width: "100%",
        height: "100%",
        borderRadius: 3,
        objectFit: "cover",
        overflow: "hidden",
        ...sx,
      }}
    />
  );
}

export default ProfileImageCard;
