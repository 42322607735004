// src/components/ChatInterface.jsx
import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  Avatar,
  Dialog,
  InputAdornment,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import colors from "../utils/colors";
import botIcon from "../assets/bot-image.jpg";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import workflowImage from "../assets/workflowImage.jpg";
import PersonIcon from "@mui/icons-material/Person";
import { getBotMessage } from "../services/chat_response";
import { useEffect } from "react";
import { useRef } from "react";

function ChatInterface({ onClose }) {
  // State to control dialog visibility
  const [openDialog, setOpenDialog] = useState(false);

  // State to store the clicked image for display in dialog
  const [selectedImage, setSelectedImage] = useState(null);

  // Chat history state to store messages between user and bot
  const [chatHistory, setChatHistory] = useState([
    {
      sender: "bot",
      text: "Hi, I am Neo, your AI consultant for creating GenAI applications.  \nI follow the workflow in the slide below to solve your problem.  \nHow can I help you?",
      image: workflowImage,
    },
  ]);

  // State to manage the current message in the input field
  const [message, setMessage] = useState("");

  // Ref for automatically scrolling to the bottom of the chat
  const bottomRef = useRef(null);

  // Scroll to bottom whenever chat history updates
  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatHistory]);

  // Function to handle sending a message
  const handleSendMessage = async () => {
    if (message.trim() === "") return; // Ignore empty messages
    let modifiedChatString;
    const modifiedChat = chatHistory.map(
      (chat) => `${chat.sender}: ${chat.text}`
    );
    modifiedChatString = modifiedChat.join("  \n");

    console.log("modifiedChat", modifiedChatString);

    const data = { chatHistory: modifiedChat, message };

    // Add user message to chat history
    const updatedChatHistory = [
      ...chatHistory,
      { sender: "user", text: message },
    ];
    setChatHistory(updatedChatHistory);

    // Clear the input field
    setMessage("");

    try {
      // Fetch the bot response using the API call
      const botResponseText = await getBotMessage(data);

      // Add bot response to chat history
      const botResponse = {
        sender: "bot",
        text: botResponseText,
      };
      setChatHistory((prevHistory) => [...prevHistory, botResponse]);
    } catch (error) {
      console.error("Error fetching bot response:", error);
      // Optionally, add an error message to the chat history
      setChatHistory((prevHistory) => [
        ...prevHistory,
        {
          sender: "bot",
          text: "Sorry, I couldn't retrieve a response. Please try again later.",
        },
      ]);
    }
  };
  // Function to handle image click
  const handleImageClick = (image) => {
    setSelectedImage(image);
    setOpenDialog(true);
  };

  return (
    <Box
      sx={{
        backgroundColor: colors.chatBackground,
        borderRadius: 2,
        p: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        maxWidth: "100%",
        height: "100%",
        boxShadow: 3,
        position: "relative",
        maxHeight: "calc(100vh - 100px)",
        overflowY: "auto",
        mb: 2,
      }}
    >
      {/* Header Section */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: colors.textPrimary,
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          p: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Avatar
            src={botIcon}
            alt="Bot Icon"
            sx={{ width: 32, height: 32, border: "2px solid green" }}
          />
          <Box>
            <Typography
              variant="subtitle1"
              sx={{ color: colors.textSecondary, fontWeight: "bold" }}
            >
              Neo
            </Typography>
            <Typography variant="caption" sx={{ color: colors.textSecondary }}>
              Online
            </Typography>
          </Box>
        </Box>
        <IconButton onClick={onClose} sx={{ color: colors.textSecondary }}>
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Chat History */}
      <Box
        sx={{
          flex: 1,
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          mt: 2,
          px: 1,
          height: "100%",
          WebkitOverflowScrolling: "touch", // For smooth scrolling on iOS
          scrollbarWidth: "thin", // For Firefox
          scrollbarColor: `${colors.textPrimary} ${colors.background}`, // Custom scrollbar thumb and track colors
          "&::-webkit-scrollbar": {
            width: "8px", // Custom scrollbar width
            borderRadius: "10px", // Optional rounding for consistency
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: colors.chatBubbleSecondary, // Scrollbar thumb color
            borderRadius: "10px", // Rounding scrollbar thumb edges
            border: `1px solid ${colors.chatBubbleSecondary}`, // Border for scrollbar thumb
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: colors.background, // Scrollbar track background
            borderRadius: "10px", // Rounding scrollbar track edges
            border: `1px solid ${colors.background}`, // Border for scrollbar track
          },
        }}
      >
        {chatHistory.map((msg, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: msg.sender === "user" ? "flex-end" : "flex-start",
            }}
          >
            {/* Sender Info */}
            <Box
              sx={{ display: "flex", alignItems: "center", gap: 1, mb: 0.5 }}
            >
              {msg.sender === "bot" && (
                <Avatar
                  src={msg.sender === "bot" ? botIcon : undefined}
                  sx={{ width: 24, height: 24 }}
                />
              )}
              <Typography
                variant="subtitle2"
                sx={{ color: colors.textSecondary, fontWeight: "bold" }}
              >
                {msg.sender === "bot" ? "Neo" : "User"}
              </Typography>
              {msg.sender === "user" && (
                <Avatar sx={{ width: 24, height: 24 }}>
                  <PersonIcon />
                </Avatar>
              )}
            </Box>
            {/* Message Bubble */}
            <Box
              sx={{
                backgroundColor:
                  msg.sender === "user"
                    ? colors.chatBubbleSecondary
                    : colors.chatBubble,
                color: colors.textSecondary,
                p: 1.5,
                borderRadius: 2,
                maxWidth: "70%",
              }}
            >
              {msg.text && typeof msg.text === "string" ? (
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  children={msg.text}
                  components={{
                    p: ({ node, ...props }) => (
                      <Typography variant="body2" {...props} />
                    ),
                    a: ({ node, ...props }) => (
                      <a style={{ color: colors.textSecondary }} {...props} />
                    ),
                  }}
                />
              ) : (
                <Typography variant="body2">Invalid content format</Typography>
              )}
              {msg.image && (
                <Box
                  component="img"
                  src={msg.image}
                  alt="Chat Image"
                  sx={{
                    width: "100%",
                    maxWidth: 200,
                    borderRadius: 2,
                    cursor: "pointer",
                    mt: 1,
                  }}
                  onClick={() => handleImageClick(msg.image)}
                />
              )}
            </Box>
            {/* Empty div to act as a scroll-to-bottom ref */}
            <div ref={bottomRef} />
          </Box>
        ))}
      </Box>

      {/* User Input Section */}
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <TextField
          variant="outlined"
          placeholder="Type a message..."
          fullWidth
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleSendMessage();
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton color="primary" onClick={handleSendMessage}>
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            ),
            sx: {
              borderRadius: "20px", // Makes the TextField rounded like a capsule
              backgroundColor: "#f4f4f4", // Optional: Add a background color
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "30px", // Makes the entire TextField rounded
              backgroundColor: "#f4f4f4", // Optional background color for the TextField
              paddingRight: "8px", // Adjusts space for the Send icon
            },
            position: "sticky",
            bottom: 0, // Ensure it stays at the bottom
            zIndex: 1, // Keep it above chat history
          }}
        />
      </Box>
      {/* Image Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <Box
          component="img"
          src={selectedImage}
          alt="Enlarged Image"
          sx={{ width: "100%", maxWidth: 700 }}
        />
      </Dialog>
    </Box>
  );
}

export default ChatInterface;
