const colors = {
  background: "#b88669",
  textPrimary: "#ffffff",
  textSecondary: "#000000",
  chatBackground: "#f4f4f4",
  chatBubble: "#9EE6EF",
  chatBubbleSecondary: "#EFA79E",
  buttonBorder: "#ffffff",
};

export default colors;
